<script setup lang="ts">
import { useCustomizerStore } from '@/stores/customizer';
import Logo from '../logo/Logo.vue';

const customizer = useCustomizerStore();
const { data: userData } = await useAuth();
const sidebarMenu = [
    // { title: 'Modern', icon: "mdi-home", chip: 'New', chipColor: 'surface', chipBgColor: 'secondary', to: '/'},
      {title: "Tableau de bord", to: "/dashboard", icon: "mdi-home", admin: true},
      {title: "Stats", to: "/stats", icon: "mdi-finance", admin: true},
      {header: 'Catalogue', icon: "mdi-book-open-variant" },
      {title: "Produits", to: "/products", icon: 'mdi-bottle-wine'},
      {title: "Vignerons", to: "/producers", icon: "mdi-human-male-male"},
      {title: "Categories", to: "/categories", icon: "mdi-format-list-checkbox"},
      {header: 'Commandes', icon: "mdi-basket"},
      {title: "Commandes", to: "/orders", icon: "mdi-cart"},
      {title: "Chèques Cadeaux", to: "/vouchers", icon: "mdi-cash"},
      {title: "Utilisateurs", to: "/users", icon: "mdi-account"},
      {title: "Messages", to: "/messages", icon: "mdi-email-outline"},
      {header: 'Expedition', icon: "mdi-truck"},
      {title: "Pays/Formats/Zones", to: "/shipping/config", icon: "mdi-earth"},
      {title: "Packagings", to: "/shipping/packaging", icon: "mdi-package-variant"},
      {title: "Shipping", to: "/shipping", icon: "mdi-tune"},
    //   {title: "GLS", to: "/shipping/gls", icon: "mdi-alpha-g-box-outline"},
      {title: "Stuart", to: "/shipping/stuart", icon: "mdi-cube-outline"},
    //   {header: 'Services & APIS' },
    //   {title: "Cloudinary", to: "/services/cloudinary", icon: CloudUpIcon},
      {header: 'Configuration', admin: true, icon: "mdi-keyboard-settings" },
      // {title: "i18n", to: "/i18n", icon: "mdi-translate"}],
      // {title: "CMS", to: "/cms", icon: "mdi-folder-text"},
      {title: "Stories", to: "/stories", icon: "mdi-pen", admin: true},
      {title: "Redis ", to: "/monitoring/redis", icon: "mdi-chart-multiple", admin: true},
      {title: "Performance", to: "/monitoring/perfs", icon: "mdi-chart-multiple", admin: true},
      {header: 'Settings', admin: true },
      {title: "Config", to: "/settings", icon: "mdi-cog", admin: true},
      
    ]

const filteredMenu = () => {
    return sidebarMenu.filter((item) => {
        if (item.admin) {
            return userData.value && userData.value.role == "admin"
        }
        return true;
    });
};
</script>

<template>
  <v-navigation-drawer left v-model="customizer.Sidebar_drawer" elevation="0" rail-width="73" mobile-breakpoint="960" app
    class="leftSidebar" rail expand-on-hover width="270">
    <!---Logo part -->
    <div class="logo">
      <v-img src="/img/logos/lcdp.png" class="ml-2"  height="75" min-width="250px" max-width="250px" width="20px" />
    </div>
    <!-- ---------------------------------------------- -->
    <!---Navigation -->
    <!-- ---------------------------------------------- -->
    <div class="scrollnavbar">
      <v-list class="">
        <!---Menu Loop -->
        <template v-for="(item, i) in filteredMenu()">
          <v-list-subheader v-if="item.header " theme="TABLE_THEME" class=" smallCap text-uppercase w-full pl-0 font-bold justify-start flex align-items-center">
            <div  class="icon-container text-center pl-4 ml-1">
              <v-icon size="35" theme="TABLE_THEME" color="red-600" :icon="item.icon"  />
            </div>
            <div class="inline-block ml-2  mini-text  text-red-900">{{ item.header }} </div>
          </v-list-subheader>
          <v-list-item :to="item.type === 'external' ? '' : item.to" :href="item.type === 'external' ? item.to : ''"
            rounded class="!pl-0 " :disabled="item.disabled" :target="item.type === 'external' ? '_blank' : ''"
            v-else >
            <!---If icon-->
            <template v-slot:prepend theme="CARD_THEME">
              <v-icon class="ml-3" :icon="item.icon" />
            </template>
            <v-list-item-title>{{ item.title }}</v-list-item-title>

          </v-list-item>
          <!---End Single Item-->
        </template>
      </v-list>

    </div>
  </v-navigation-drawer>
</template>
<style lang="scss">
.leftSidebar {
  .logo {
    padding-left: 0px;
    width: 50px;
    overflow: hidden;
    margin: auto;
  }
  &.v-navigation-drawer--is-hovering{
    .logo {
      padding-left: 0px;
      width: 100%;
    }
  }
}
/*This is for the logo*/
.leftSidebar {
    box-shadow: none !important;
    border-right: 1px solid rgb(var(--v-theme-borderColor));
    .v-list-subheader__text{
      text-overflow: clip;
      display: flex;
      text-align: center;
      .icon-container {
        width: 50px !important;
        
      }
    }
    .logo {
        padding-left: 5px;
        width: 50px;
        overflow: hidden;
    }

    .mini-icon {
        display: none;
    }

    .mini-text {
        // display: block;
        color: transparent; 
        height: 0px;
        // &::before{
        //     color: orange;
        //     content: "...";
        //     display: block;
        //     float: left;
        // }
    }
}

.leftSidebar:hover {
    
    .mini-text {
        // display: block;
        color: rgb(175, 116, 5); 
        font-weight: bold;
        line-height: 40px;
        // &::before{
        //     display: none;
        // }
    }
}
/*This is for the Vertical sidebar*/
.scrollnavbar {
    height: calc(100vh );

    .userbottom {
        position: fixed;
        bottom: 0px;
        width: 100%;
    }

    .smallCap {
        padding: 3px 0 !important;
        font-size: 0.975rem;
        font-weight: 500;
        margin-top: 10px;
        color: rgb(var(--v-theme-textPrimary));
        padding-inline-start: unset !important;
        margin: 5px 0 !important;
        &:first-child {
            margin-top: 0 !important;
        }
    }

    /*General Menu css*/
    .v-list-group__items .v-list-item,
    .v-list-item {
        border-radius: 10px;
        padding-inline-start: calc(12px + var(--indent-padding) / 10) !important;

        margin: 0 0 2px;

        &:hover {
            color: rgb(var(--v-theme-primary));
        }

        .v-list-item__prepend {
            margin-inline-end: 13px;
            & > .v-icon {
              opacity: 1 !important;
            }
        }

        .v-list-item__append {
            font-size: 0.875rem;

            .v-icon {
                margin-left: -10px;
                margin-inline-start: 13px;
            }
        }

        .v-list-item-title {
            font-size: 0.975rem;
        }
    }

    /*This is for the dropdown*/
    .v-list {
        color: rgb(var(--v-theme-textPrimary));

        > .v-list-item.v-list-item--active,
        .v-list-item--active > .v-list-item__overlay {
            background: transparent;
            color: orange;
            .v-list-item__prepend{
              i::before{
                font-size: 34px;
              }
            }
        }

        > .v-list-group {
            position: relative;

            > .v-list-item--active,
            > .v-list-item--active:hover {
                background: rgb(var(--v-theme-primary));
                color: white;
            }

            .v-list-group__items .v-list-item.v-list-item--active,
            .v-list-group__items .v-list-item.v-list-item--active > .v-list-item__overlay {
                color: rgb(var(--v-theme-primary));
            }
        }
    }
}

.v-navigation-drawer--rail {
    .scrollnavbar .v-list .v-list-group__items,
    .hide-menu {
        opacity: 1;
    }


    .leftPadding {
        margin-left: 0px;
    }
}

@media only screen and (min-width: 1170px) {
    .mini-sidebar {
        .logo {
            width: 40px;
            overflow: hidden;
            padding-left: 0;
        }

        .mini-icon {
            display: block;
        }

        .sidebarchip.hide-menu{
            opacity: 0;
        }

        .userbottom .hide-menu{
            opacity:0;
        }

        .mini-text {
            display: none;
        }

        .v-list {
            padding: 14px !important;
        }

        .leftSidebar:hover {
          .logo {
                width: 100%;
                
            }
            box-shadow: rgba(145 158 171 / 30%) 0px 0px 2px 0px, rgba(145 158 171 / 12%) 0px 12px 24px -4px !important;

            .mini-icon {
                display: none;
            }

            
        }

        .v-navigation-drawer--expand-on-hover:hover {
            .logo {
                width: 100%;
                background-color: yellow;
            }

            .v-list .v-list-group__items,
            .hide-menu {
                opacity: 1;
            }
            .sidebarchip.hide-menu{
                opacity: 1;
            }
            .userbottom .hide-menu{
                opacity: 1;
            }
            .mini-text {
                display: block;
                color: green; 
                &::before{
                    content: "--";
                    display: block;
                }
            }
        }
    }
}

// scrollbar 
.ps__rail-y {
    z-index: 9;
}

.v-navigation-drawer{
    // box-shadow: none !important;
    border-right: 1px solid rgb(var(--v-theme-borderColor)) !important;
}

</style>