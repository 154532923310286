
<template>
    <!-- ---------------------------------------------- -->
    <!-- search1 -->
    <!-- ------------------------------------------------>
    <v-menu :close-on-content-click="true">
        <template v-slot:activator="{ props }">
            <v-btn icon class="custom-hover-primary" size="small" variant="text" color="primary" v-bind="props">
                <v-icon icon="mdi-magnify" stroke-width="1.5" size="25" />
            </v-btn>
        </template>
        <v-sheet width="360" elevation="10" rounded="md">
            <div class="d-flex align-center justify-space-between pa-5">
                <v-text-field placeholder="Search" color="primary" density="compact" variant="outlined" hide-details  v-model="searchQuery" @keyup="runSearch"></v-text-field>
            </div>
            <v-divider></v-divider>
            <!-- <perfect-scrollbar style="height: 380px"> -->
                <v-list class="pt-0 pb-5" lines="two">
                    <v-list-item
                        v-for="(item, index) in searchData"
                        :value="item"
                        :key="index"
                        :to="item.record_class == 'Product' ? `/products/${item.record_source.id}` : `/producers/${item.record_source.id}`"
                        class=" py-2 flex search-results">
                        <div class="w-3/12">
                            <CVImg :product="item.record_source" :resourceName="item.record_class.toLowerCase()" :transform="item.record_class == 'Product' ? {rotate: 90} : {}" />
                        </div>
                        <div class="w-9/12">
                            <h6 class="text-subtitle-1 font-weight-bold mb-1">{{ item.record_source.name }}</h6>
                            <p class="text-subtitle-2 text-medium-emphasis">{{ item.record_class }}</p>
                        </div>
                    </v-list-item>
                </v-list>
            <!-- </perfect-scrollbar> -->
        </v-sheet>
    </v-menu>
</template>

<script setup>
const searchQuery = ref('')
let searchTimer = null; 
const querying = ref(false)
const showResuts = ref(false)
const searchData = ref([])
const runSearch = ()=>{
  if(searchQuery.value.length < 3) return;
  clearTimeout(searchTimer); 
  searchTimer = setTimeout(()=> {
    search()
  }, 800)
        
}

const search = async () => {
  querying.value = true
  const params = {
    page: 1,
    query: searchQuery,
    sorting: "asc"
  }
  const searchRequest = await  useAPIFetch('/search', {params})
searchData.value = searchRequest.data.value.results
  
  querying.value = false
  showResuts.value = true

}
</script>

<style lang="scss"> 
.search-results{
    cursor: pointer;
    .v-list-item__content{
        display: flex;
    }
}
</style>