import type { UseFetchOptions } from 'nuxt/app'
import { defu } from 'defu'

// Extend the UseFetchOptions to handle custom headers
interface ExtendedUseFetchOptions<T> extends UseFetchOptions<T> {
  headers?: Record<string, string>;
}


export function useAPIFetch<T>(url: string, options: UseFetchOptions<T> = {}, lazy: Boolean) {
  const userAuth = useCookie('auth:token')
  const guestAuth = useCookie('guest-token')
  const config = useRuntimeConfig()
  const method =  options.method ? options.method : "GET"
  delete options.method  
  
  const headers: Record<string, string> = {};
  if(userAuth.value)
    headers["Authorization"] = `Bearer ${userAuth.value}`
  else if(guestAuth.value)
    headers["Token"] = guestAuth.value
  const defaults: UseFetchOptions<T> = {
    baseURL: config.public.baseURL,
    // cache request
    method,
    key: url,
    initialCache: true,
    watch: false,
    // set user token if connected
    headers,

    async onResponse({ request, response, options }) {
      if(!guestAuth.value &&  response.headers.get("Token"))
        guestAuth.value = response.headers.get("Token")
    },

    // async onResponseError(_ctx) {
    //   throw new Error("API Error");
    //   return _ctx
    // }
  }
  if(method != "GET"){
    defaults.body = options.params
    delete options.params
  }

  // for nice deep defaults, please use unjs/defu
  
  const params = defu(options, defaults);
  if(lazy)
    return useLazyFetch(url, params);
  else
    return useFetch(url, params);
}
