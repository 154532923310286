import mitt from 'mitt';



export default defineNuxtPlugin((nuxtApp) => {
  const { signIn, status, data } = useAuth();
  const role = data.value?.role;
  const canDelete =  role == "admin" ||  role == "manager";
  return {
    provide: {
      canDelete
    }
  }  
});