import { default as indexTZXcsXx1TLMeta } from "/var/www/admin/pages/categories/index.vue?macro=true";
import { default as dashboardv1jZxJrVmlMeta } from "/var/www/admin/pages/dashboard.vue?macro=true";
import { default as login8Boh8O7nx2Meta } from "/var/www/admin/pages/login.vue?macro=true";
import { default as index8GD6lMQzdyMeta } from "/var/www/admin/pages/messages/index.vue?macro=true";
import { default as perfs3zim4uYfqmMeta } from "/var/www/admin/pages/monitoring/perfs.vue?macro=true";
import { default as redisBzkliHG8vaMeta } from "/var/www/admin/pages/monitoring/redis.vue?macro=true";
import { default as _91id_93lFJO6yV8GQMeta } from "/var/www/admin/pages/orders/[id].vue?macro=true";
import { default as indexkNK3pqs4j8Meta } from "/var/www/admin/pages/orders/index.vue?macro=true";
import { default as _91id_93wYKbBMmQE8Meta } from "/var/www/admin/pages/producers/[id].vue?macro=true";
import { default as indexnk2UFiCKkNMeta } from "/var/www/admin/pages/producers/index.vue?macro=true";
import { default as _91id_933LRkDjbGlwMeta } from "/var/www/admin/pages/products/[id].vue?macro=true";
import { default as index1nzY1QW075Meta } from "/var/www/admin/pages/products/index.vue?macro=true";
import { default as profileYseO4MEDC9Meta } from "/var/www/admin/pages/profile.vue?macro=true";
import { default as indexgKYiSQzV3oMeta } from "/var/www/admin/pages/settings/index.vue?macro=true";
import { default as configjykNeHhKAxMeta } from "/var/www/admin/pages/shipping/config.vue?macro=true";
import { default as countriesObS5QiB5bGMeta } from "/var/www/admin/pages/shipping/countries.vue?macro=true";
import { default as glsdkkYQZN1qTMeta } from "/var/www/admin/pages/shipping/gls.vue?macro=true";
import { default as indexcDrYC7C9a7Meta } from "/var/www/admin/pages/shipping/index.vue?macro=true";
import { default as packaging0HiDRXX9TkMeta } from "/var/www/admin/pages/shipping/packaging.vue?macro=true";
import { default as stuartsOxsLBTkYbMeta } from "/var/www/admin/pages/shipping/stuart.vue?macro=true";
import { default as statsEKCOF5zO9NMeta } from "/var/www/admin/pages/stats.vue?macro=true";
import { default as _91id_93Vs9QSrCCzWMeta } from "/var/www/admin/pages/stories/[id].vue?macro=true";
import { default as indexbGQFkhVIM1Meta } from "/var/www/admin/pages/stories/index.vue?macro=true";
import { default as _91id_93H80ySBagSuMeta } from "/var/www/admin/pages/users/[id].vue?macro=true";
import { default as index5h6GGBLwo7Meta } from "/var/www/admin/pages/users/index.vue?macro=true";
import { default as _91id_93LvpwqHqUrtMeta } from "/var/www/admin/pages/vouchers/[id].vue?macro=true";
import { default as indexPfG1Lcoz8GMeta } from "/var/www/admin/pages/vouchers/index.vue?macro=true";
import { default as component_45stubCEe0sEJXafMeta } from "/var/www/admin/node_modules/nuxt/dist/pages/runtime/component-stub?macro=true";
import { default as component_45stubCEe0sEJXaf } from "/var/www/admin/node_modules/nuxt/dist/pages/runtime/component-stub";
export default [
  {
    name: indexTZXcsXx1TLMeta?.name ?? "categories",
    path: indexTZXcsXx1TLMeta?.path ?? "/categories",
    meta: indexTZXcsXx1TLMeta || {},
    alias: indexTZXcsXx1TLMeta?.alias || [],
    redirect: indexTZXcsXx1TLMeta?.redirect,
    component: () => import("/var/www/admin/pages/categories/index.vue").then(m => m.default || m)
  },
  {
    name: dashboardv1jZxJrVmlMeta?.name ?? "dashboard",
    path: dashboardv1jZxJrVmlMeta?.path ?? "/dashboard",
    meta: dashboardv1jZxJrVmlMeta || {},
    alias: dashboardv1jZxJrVmlMeta?.alias || [],
    redirect: dashboardv1jZxJrVmlMeta?.redirect,
    component: () => import("/var/www/admin/pages/dashboard.vue").then(m => m.default || m)
  },
  {
    name: login8Boh8O7nx2Meta?.name ?? "login",
    path: login8Boh8O7nx2Meta?.path ?? "/login",
    meta: login8Boh8O7nx2Meta || {},
    alias: login8Boh8O7nx2Meta?.alias || [],
    redirect: login8Boh8O7nx2Meta?.redirect,
    component: () => import("/var/www/admin/pages/login.vue").then(m => m.default || m)
  },
  {
    name: index8GD6lMQzdyMeta?.name ?? "messages",
    path: index8GD6lMQzdyMeta?.path ?? "/messages",
    meta: index8GD6lMQzdyMeta || {},
    alias: index8GD6lMQzdyMeta?.alias || [],
    redirect: index8GD6lMQzdyMeta?.redirect,
    component: () => import("/var/www/admin/pages/messages/index.vue").then(m => m.default || m)
  },
  {
    name: perfs3zim4uYfqmMeta?.name ?? "monitoring-perfs",
    path: perfs3zim4uYfqmMeta?.path ?? "/monitoring/perfs",
    meta: perfs3zim4uYfqmMeta || {},
    alias: perfs3zim4uYfqmMeta?.alias || [],
    redirect: perfs3zim4uYfqmMeta?.redirect,
    component: () => import("/var/www/admin/pages/monitoring/perfs.vue").then(m => m.default || m)
  },
  {
    name: redisBzkliHG8vaMeta?.name ?? "monitoring-redis",
    path: redisBzkliHG8vaMeta?.path ?? "/monitoring/redis",
    meta: redisBzkliHG8vaMeta || {},
    alias: redisBzkliHG8vaMeta?.alias || [],
    redirect: redisBzkliHG8vaMeta?.redirect,
    component: () => import("/var/www/admin/pages/monitoring/redis.vue").then(m => m.default || m)
  },
  {
    name: _91id_93lFJO6yV8GQMeta?.name ?? "orders-id",
    path: _91id_93lFJO6yV8GQMeta?.path ?? "/orders/:id()",
    meta: _91id_93lFJO6yV8GQMeta || {},
    alias: _91id_93lFJO6yV8GQMeta?.alias || [],
    redirect: _91id_93lFJO6yV8GQMeta?.redirect,
    component: () => import("/var/www/admin/pages/orders/[id].vue").then(m => m.default || m)
  },
  {
    name: indexkNK3pqs4j8Meta?.name ?? "orders",
    path: indexkNK3pqs4j8Meta?.path ?? "/orders",
    meta: indexkNK3pqs4j8Meta || {},
    alias: indexkNK3pqs4j8Meta?.alias || [],
    redirect: indexkNK3pqs4j8Meta?.redirect,
    component: () => import("/var/www/admin/pages/orders/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93wYKbBMmQE8Meta?.name ?? "producers-id",
    path: _91id_93wYKbBMmQE8Meta?.path ?? "/producers/:id()",
    meta: _91id_93wYKbBMmQE8Meta || {},
    alias: _91id_93wYKbBMmQE8Meta?.alias || [],
    redirect: _91id_93wYKbBMmQE8Meta?.redirect,
    component: () => import("/var/www/admin/pages/producers/[id].vue").then(m => m.default || m)
  },
  {
    name: indexnk2UFiCKkNMeta?.name ?? "producers",
    path: indexnk2UFiCKkNMeta?.path ?? "/producers",
    meta: indexnk2UFiCKkNMeta || {},
    alias: indexnk2UFiCKkNMeta?.alias || [],
    redirect: indexnk2UFiCKkNMeta?.redirect,
    component: () => import("/var/www/admin/pages/producers/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_933LRkDjbGlwMeta?.name ?? "products-id",
    path: _91id_933LRkDjbGlwMeta?.path ?? "/products/:id()",
    meta: _91id_933LRkDjbGlwMeta || {},
    alias: _91id_933LRkDjbGlwMeta?.alias || [],
    redirect: _91id_933LRkDjbGlwMeta?.redirect,
    component: () => import("/var/www/admin/pages/products/[id].vue").then(m => m.default || m)
  },
  {
    name: index1nzY1QW075Meta?.name ?? "products",
    path: index1nzY1QW075Meta?.path ?? "/products",
    meta: index1nzY1QW075Meta || {},
    alias: index1nzY1QW075Meta?.alias || [],
    redirect: index1nzY1QW075Meta?.redirect,
    component: () => import("/var/www/admin/pages/products/index.vue").then(m => m.default || m)
  },
  {
    name: profileYseO4MEDC9Meta?.name ?? "profile",
    path: profileYseO4MEDC9Meta?.path ?? "/profile",
    meta: profileYseO4MEDC9Meta || {},
    alias: profileYseO4MEDC9Meta?.alias || [],
    redirect: profileYseO4MEDC9Meta?.redirect,
    component: () => import("/var/www/admin/pages/profile.vue").then(m => m.default || m)
  },
  {
    name: indexgKYiSQzV3oMeta?.name ?? "settings",
    path: indexgKYiSQzV3oMeta?.path ?? "/settings",
    meta: indexgKYiSQzV3oMeta || {},
    alias: indexgKYiSQzV3oMeta?.alias || [],
    redirect: indexgKYiSQzV3oMeta?.redirect,
    component: () => import("/var/www/admin/pages/settings/index.vue").then(m => m.default || m)
  },
  {
    name: configjykNeHhKAxMeta?.name ?? "shipping-config",
    path: configjykNeHhKAxMeta?.path ?? "/shipping/config",
    meta: configjykNeHhKAxMeta || {},
    alias: configjykNeHhKAxMeta?.alias || [],
    redirect: configjykNeHhKAxMeta?.redirect,
    component: () => import("/var/www/admin/pages/shipping/config.vue").then(m => m.default || m)
  },
  {
    name: countriesObS5QiB5bGMeta?.name ?? "shipping-countries",
    path: countriesObS5QiB5bGMeta?.path ?? "/shipping/countries",
    meta: countriesObS5QiB5bGMeta || {},
    alias: countriesObS5QiB5bGMeta?.alias || [],
    redirect: countriesObS5QiB5bGMeta?.redirect,
    component: () => import("/var/www/admin/pages/shipping/countries.vue").then(m => m.default || m)
  },
  {
    name: glsdkkYQZN1qTMeta?.name ?? "shipping-gls",
    path: glsdkkYQZN1qTMeta?.path ?? "/shipping/gls",
    meta: glsdkkYQZN1qTMeta || {},
    alias: glsdkkYQZN1qTMeta?.alias || [],
    redirect: glsdkkYQZN1qTMeta?.redirect,
    component: () => import("/var/www/admin/pages/shipping/gls.vue").then(m => m.default || m)
  },
  {
    name: indexcDrYC7C9a7Meta?.name ?? "shipping",
    path: indexcDrYC7C9a7Meta?.path ?? "/shipping",
    meta: indexcDrYC7C9a7Meta || {},
    alias: indexcDrYC7C9a7Meta?.alias || [],
    redirect: indexcDrYC7C9a7Meta?.redirect,
    component: () => import("/var/www/admin/pages/shipping/index.vue").then(m => m.default || m)
  },
  {
    name: packaging0HiDRXX9TkMeta?.name ?? "shipping-packaging",
    path: packaging0HiDRXX9TkMeta?.path ?? "/shipping/packaging",
    meta: packaging0HiDRXX9TkMeta || {},
    alias: packaging0HiDRXX9TkMeta?.alias || [],
    redirect: packaging0HiDRXX9TkMeta?.redirect,
    component: () => import("/var/www/admin/pages/shipping/packaging.vue").then(m => m.default || m)
  },
  {
    name: stuartsOxsLBTkYbMeta?.name ?? "shipping-stuart",
    path: stuartsOxsLBTkYbMeta?.path ?? "/shipping/stuart",
    meta: stuartsOxsLBTkYbMeta || {},
    alias: stuartsOxsLBTkYbMeta?.alias || [],
    redirect: stuartsOxsLBTkYbMeta?.redirect,
    component: () => import("/var/www/admin/pages/shipping/stuart.vue").then(m => m.default || m)
  },
  {
    name: statsEKCOF5zO9NMeta?.name ?? "stats",
    path: statsEKCOF5zO9NMeta?.path ?? "/stats",
    meta: statsEKCOF5zO9NMeta || {},
    alias: statsEKCOF5zO9NMeta?.alias || [],
    redirect: statsEKCOF5zO9NMeta?.redirect,
    component: () => import("/var/www/admin/pages/stats.vue").then(m => m.default || m)
  },
  {
    name: _91id_93Vs9QSrCCzWMeta?.name ?? "stories-id",
    path: _91id_93Vs9QSrCCzWMeta?.path ?? "/stories/:id()",
    meta: _91id_93Vs9QSrCCzWMeta || {},
    alias: _91id_93Vs9QSrCCzWMeta?.alias || [],
    redirect: _91id_93Vs9QSrCCzWMeta?.redirect,
    component: () => import("/var/www/admin/pages/stories/[id].vue").then(m => m.default || m)
  },
  {
    name: indexbGQFkhVIM1Meta?.name ?? "stories",
    path: indexbGQFkhVIM1Meta?.path ?? "/stories",
    meta: indexbGQFkhVIM1Meta || {},
    alias: indexbGQFkhVIM1Meta?.alias || [],
    redirect: indexbGQFkhVIM1Meta?.redirect,
    component: () => import("/var/www/admin/pages/stories/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93H80ySBagSuMeta?.name ?? "users-id",
    path: _91id_93H80ySBagSuMeta?.path ?? "/users/:id()",
    meta: _91id_93H80ySBagSuMeta || {},
    alias: _91id_93H80ySBagSuMeta?.alias || [],
    redirect: _91id_93H80ySBagSuMeta?.redirect,
    component: () => import("/var/www/admin/pages/users/[id].vue").then(m => m.default || m)
  },
  {
    name: index5h6GGBLwo7Meta?.name ?? "users",
    path: index5h6GGBLwo7Meta?.path ?? "/users",
    meta: index5h6GGBLwo7Meta || {},
    alias: index5h6GGBLwo7Meta?.alias || [],
    redirect: index5h6GGBLwo7Meta?.redirect,
    component: () => import("/var/www/admin/pages/users/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93LvpwqHqUrtMeta?.name ?? "vouchers-id",
    path: _91id_93LvpwqHqUrtMeta?.path ?? "/vouchers/:id()",
    meta: _91id_93LvpwqHqUrtMeta || {},
    alias: _91id_93LvpwqHqUrtMeta?.alias || [],
    redirect: _91id_93LvpwqHqUrtMeta?.redirect,
    component: () => import("/var/www/admin/pages/vouchers/[id].vue").then(m => m.default || m)
  },
  {
    name: indexPfG1Lcoz8GMeta?.name ?? "vouchers",
    path: indexPfG1Lcoz8GMeta?.path ?? "/vouchers",
    meta: indexPfG1Lcoz8GMeta || {},
    alias: indexPfG1Lcoz8GMeta?.alias || [],
    redirect: indexPfG1Lcoz8GMeta?.redirect,
    component: () => import("/var/www/admin/pages/vouchers/index.vue").then(m => m.default || m)
  },
  {
    name: component_45stubCEe0sEJXafMeta?.name ?? undefined,
    path: component_45stubCEe0sEJXafMeta?.path ?? "/",
    meta: component_45stubCEe0sEJXafMeta || {},
    alias: component_45stubCEe0sEJXafMeta?.alias || [],
    redirect: component_45stubCEe0sEJXafMeta?.redirect,
    component: component_45stubCEe0sEJXaf
  }
]