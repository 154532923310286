<template>
  <NuxtImg 
    class="product-pic"
    :src="src"
    format="webp"
    v-bind="sizeAttributes" 
    provider="storyblok"
    :modifiers="{filters: transform}"
    :placeholder="placeholder"
    />
</template>

<script setup>
const sizes = {mini: '98w', thumb: '140w', full: '280w'}
const sets = {
  png: [],
  webp: []
}
const placeholder =  computed(()=>{
  const size = `${sizeAttributes.value.width}x${sizeAttributes.value.height}`
  if(props.transform && Object.keys(props.transform).includes("rotate"))
    return "https://a.storyblok.com/f/251488/300x84/e87728b52d/product_placeholder.webp/m/250x0"
  else
    if(props.resourceName === "product")
      return `https://a.storyblok.com/f/251488/280x1000/e30532f67d/product_placeholder.svg/m/${size}`
    else
      return `https://a.storyblok.com/f/251488/250x336/96e044bd69/logo.png`
})

const sizeAttributes = computed(()=>{
  return {
    width: props.width,
    height: props.height,
    fit: props.fit
  }
})

const src = computed(()=>{
  const size = `${sizeAttributes.value.width}x${sizeAttributes.value.height}`
  const isStoryblok = props.product.picture?.includes("a.storyblok.com")
  if(isStoryblok){
    const url = new URL(props.product.picture)
    return ["https://a.storyblok.com", url.pathname.replace(/\/a\.storyblok\.com/, "")].join()
s  }
  else
  if(props.resourceName === "product")
      return `https://a.storyblok.com/f/251488/280x1000/e30532f67d/product_placeholder.svg`
  else
    return `https://a.storyblok.com/f/251488/250x336/96e044bd69/logo.png`
})
const props = defineProps({

  product: {
    type: Object,
    required: true,
    default: {}
  },
  url: {
    type: String,
    default: ""
  },
  width: {
    type: Number,
    default: 0
  },
  height: {
    type: Number,
    default: 0
  },
  transform: {
    type: Object,
    default: {}
  },
  fit: {
    type: String,
    default: null
  },
  resourceName: {
    type: String,
    default: "product"
  },
 
})
watch(()=>props.product, ()=>{
}, {deep: true})
</script>
<style lang="scss">
.product-pic {
  width: 120px;

}
</style>