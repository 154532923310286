import mitt from 'mitt';

const emitter = mitt();
export default defineNuxtPlugin((nuxtApp) => {
  return {
    provide: {
      customEventBus: {
        $on: emitter.on,
        $emit: emitter.emit,
      }
    }
  }  
});