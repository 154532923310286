<template>

  <div v-if="loading" class="loading-screen left-0 top-0 bg-orange/30 ">
      <div class=" bg-gray-300 w-full opacity-70 h-full absolute">
      </div>
      <div class="flex w-full justify-center h-full items-center">
        <v-progress-circular
      :size="70"
      :width="7"
      color="purple"
      indeterminate
    ></v-progress-circular>
      </div>
    </div>

</template>
<script setup>
import { VProgressCircular } from 'vuetify/lib/components/index.mjs';
const loading = ref(true);
const pageLoading = ref(true);
const through = ref(false);
const { $customEventBus, hook } = useNuxtApp();
const router = useRouter();
router.beforeEach((to, from) => {
  pageLoading.value = true;
})
hook("page:finish", () => {
  if(through.value){
    return;
  }
  pageLoading.value = false;
});

$customEventBus.$on('loading', (status)=>{
  if(typeof status === "object"){
    loading.value = status.loading
    through.value = status.force
  } else
  loading.value = status
});
</script>
<style scoped lang="scss">
.loading-screen{
  position: fixed;
  width: 100%;
  height: 100vh;
  z-index: 10000;
}

</style>