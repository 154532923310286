<script setup>
import { useCustomizerStore } from '../../../stores/customizer';
import ProfileDD from './ProfileDD.vue';
import Searchbar from './Searchbar.vue';
const { $i18n } = useNuxtApp();
// import Navigations from './Navigations.vue';
const customizer = useCustomizerStore();
const showSearch = ref(false);
const showHelp = ref(false);
const appsdrawer = ref(false);
const priority = ref(customizer.setHorizontalLayout ? 0 : 0);
function searchbox() {
	showSearch.value = !showSearch.value;
}
watch(priority, (newPriority) => {
	priority.value = newPriority;
});

const pageTitle = computed(() => {
	const dynamicTitleValue = useState('titleValue')
	console.log('dynamicTitleValue', $i18n.t(`pagesTitles.${useRoute().name}`), dynamicTitleValue.value, `pagesTitles.${useRoute().name}`)
	if (dynamicTitleValue.value && $i18n.te(`pagesTitles.${useRoute().name}`))
		return $i18n.t(`pagesTitles.${useRoute().name}`, { name: dynamicTitleValue.value });
	else if ($i18n.te(`pagesTitles.${useRoute().name}`))
		return $i18n.t(`pagesTitles.${useRoute().name}`);
    });

const toggle_help_nav = () => {
	showHelp.value = !showHelp.value;
	useNuxtApp().$customEventBus.$emit("toggle_help_nav", showHelp.value)
}

</script>

<template>
	<v-app-bar elevation="2" :priority="priority" height="70" class="">

		<!-- <v-btn class="hidden-lg-and-up" icon variant="flat" @click.stop="customizer.SET_SIDEBAR_DRAWER" size="small">
            <v-icon icon="mdi-menu" size="20" stroke-width="1.5" />
        </v-btn> -->

		<!-- search mobile -->
		<!-- <v-btn class="hidden-lg-and-up ml-3" icon variant="text" color="primary" @click="searchbox">
            <SearchIcon size="17" stroke-width="1.5" />
        </v-btn> -->

		<!-- <v-sheet v-if="showSearch" class="search-sheet v-col-12">
            <Searchbar :closesearch="searchbox" />
        </v-sheet> -->
		<template #default>
			<div id="page-title" class="text-h3">
				{{ pageTitle }}
			</div>

			<!-- ---------------------------------------------- -->
			<!-- Search part -->
			<!-- ---------------------------------------------- -->
			<v-sheet class="">
				<Searchbar />
			</v-sheet>

			<!---/Search part -->

			<!-- ---------------------------------------------- -->
			<!-- Mega menu -->
			<!-- ---------------------------------------------- -->
			<div class="hidden-md-and-down">
				<!-- <Navigations /> -->
			</div>
			<v-spacer />
			<!-- ---------------------------------------------- -->
			<!---right part -->
			<!-- ---------------------------------------------- -->
			<!-- ---------------------------------------------- -->
			<!-- translate -->
			<!-- ---------------------------------------------- -->
			<!-- <LanguageDD /> -->



			<!-- ---------------------------------------------- -->
			<!-- ShoppingCart -->
			<!-- ---------------------------------------------- -->
			<!-- <v-btn icon variant="text" color="primary" to="/ecommerce/checkout">
                <v-badge  color="error" :content="getCart?.length">
                    <ShoppingCartIcon stroke-width="1.5" size="22" />
                </v-badge>
            </v-btn> -->

			<!-- ---------------------------------------------- -->
			<!-- Notification -->
			<!-- ---------------------------------------------- -->
			<!-- <NotificationDD /> -->
			<v-btn variant="text" color="primary" class="custom-hover-primary" @click="toggle_help_nav">
				<v-icon size="22" stroke-width="1.5" icon="mdi-help"></v-icon>
			</v-btn>


			<!-- ---------------------------------------------- -->
			<!-- User Profile -->
			<!-- ---------------------------------------------- -->
			<div class="ml-2">
				<ProfileDD />
			</div>
		</template>
	</v-app-bar>
</template>
