import { defineStore } from 'pinia';

const baseUrl = `${import.meta.env.VITE_API_URL}/users`;

export const useAuthStore = defineStore({
    id: 'auth',
    state: () => ({
        // initialize state from local storage to enable user to stay logged in
        // @ts-ignore
        // user: JSON.parse(localStorage.getItem('user')),
        user: {},
        returnUrl: null
    }),
    actions: {
        async login(email: string, password: string) {
            const { signIn, status, data } = useAuth();
            const signedIn = await signIn({admin_administrator:{ email, password }}, {redirect: false})
            if(status.value == "authenticated"){
                navigateTo("/orders")
            }
        
        },
        logout() {
            this.user = null;
            const {signOut} = useAuth()
            localStorage.removeItem('user');
            signOut({ callbackUrl: '/' })
        }
    }
});
