import {  getActivePinia} from "pinia"
import { useCategoriesStore } from "~/stores/categories"
export default defineNuxtPlugin( async ({ vueApp }) => {
  const store = useCategoriesStore()
  const currentPinia = getActivePinia()
  const { signIn, status, data } = useAuth();
  const categoriesData  = await store.fetchCollection()
  console.log(categoriesData)
  useState('categories', ()=>categoriesData)
  if(status.value !== "authenticated") return false;

})