import { defineStore } from 'pinia'

export const useCategoriesStore =  defineStore('categories',   () =>{
  const items = ref([])

  const headers = [
    { title: 'ID', key: 'id', align: 'start', width: "50px" },
    { title: 'Name', key: 'name', width: "250px" },
    { title: 'Parent', key: 'parent_name' },
    { title: 'Key', key: '_key' },
    { title: 'Actions', key: 'actions', width: "100px" },
  ]
  
  const fetchCollection = async ()=> {
    
    // const { data: {value: categories} } = await  useAPIFetch(`/categories.json`)
    const { data} = await useAPIFetch(`/categories.json`)
    items.value = data.value
    clearNuxtState('categories')
    useState('categories', ()=>items.value)
    return data.value
    // console.log(categories);
    // items.value = categories
    // return categories
  };

  const getCollection =  (collection = "main") => {
    // if(!items.value.length)
    //   await fetchCollection()
    const all = useState('categories')
    return all.value[collection]
  }

  const findBy = (field, value, collection = "main") => {
    return getCollection(collection).find(item => item[field] == value)
  }
  
  const where = (field, value, collection = "main") => {
    return getCollection(collection).filter(item => item[field] == value)
  }

  const createOrUpdate = async(record)=>{
    let query = {}
    if(record.value.id)
      query = {path: `categories/${record.value.id}.json`, method: "PUT", record}
    else
      query = {path: `categories.json`, method: "POST", record}
    

    const {data, status} = await useAPIFetch(query.path,{ method: query.method, params: query.record})
    items.value = data.value
    clearNuxtState('categories')
    useState('categories', ()=>items.value)
    return {data, status}
  } 

  const deleteCategory = async(id)=>{
    const {data, status} = await useAPIFetch(`categories/${id}.json`,{ method: "DELETE"})
    items.value = data.value
    clearNuxtState('categories')
    useState('categories', ()=>items.value)
    return {data, status}
  }
  

  return {
    headers,
    where,
    findBy,
    getCollection,
    fetchCollection,
    createOrUpdate,
    deleteCategory
  }


})

