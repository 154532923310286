import revive_payload_client_4sVQNw7RlN from "/var/www/admin/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/var/www/admin/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/var/www/admin/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_yVLowv6hDl from "/var/www/admin/node_modules/nuxt/dist/app/plugins/payload.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/var/www/admin/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import plugin_vue3_A0OWXRrUgq from "/var/www/admin/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/var/www/admin/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/var/www/admin/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_AOuQ1DYzjk from "/var/www/admin/node_modules/@sidebase/nuxt-auth/dist/runtime/plugin.mjs";
import plugin_KlVwwuJRCL from "/var/www/admin/node_modules/@storyblok/nuxt/dist/runtime/plugin.mjs";
import composition_sLxaNGmlSL from "/var/www/admin/node_modules/@nuxtjs/i18n/dist/runtime/plugins/composition.mjs";
import i18n_yfWm7jX06p from "/var/www/admin/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.mjs";
import chunk_reload_client_UciE0i6zes from "/var/www/admin/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import eventBus_cMx6qAgliS from "/var/www/admin/plugins/eventBus.js";
import init_plugin_z2vzLM5XBA from "/var/www/admin/plugins/init_plugin.js";
import userRole_SaHQtr9lgp from "/var/www/admin/plugins/userRole.js";
import vuetify_8NhHJigKc1 from "/var/www/admin/plugins/vuetify.js";
import vuetifyz_tooltip_ZndqE8Sz2J from "/var/www/admin/plugins/vuetifyz-tooltip.js";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  payload_client_yVLowv6hDl,
  check_outdated_build_client_8vK7RkfGxZ,
  plugin_vue3_A0OWXRrUgq,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  plugin_AOuQ1DYzjk,
  plugin_KlVwwuJRCL,
  composition_sLxaNGmlSL,
  i18n_yfWm7jX06p,
  chunk_reload_client_UciE0i6zes,
  eventBus_cMx6qAgliS,
  init_plugin_z2vzLM5XBA,
  userRole_SaHQtr9lgp,
  vuetify_8NhHJigKc1,
  vuetifyz_tooltip_ZndqE8Sz2J
]