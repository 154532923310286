import { createVNode, render } from 'vue';
import { VTooltip } from 'vuetify/lib/components/index.mjs';

export default defineNuxtPlugin(({ vueApp }) => {
  let tooltips = [];

  vueApp.directive('tooltip', {
    beforeUnmount: (el, binding) => {
      const tooltipIndex = tooltips.findIndex((tooltip) => tooltip.el === el);
      const tooltip = tooltips.splice(tooltipIndex, 1);
      if (tooltip.length > 0) {
        render(null, el);
      }
    },
    mounted: (el, binding) => {
      const { value, modifiers } = binding;
      if (!value) return;
      const { start = false, bottom = false, end = false } = modifiers;

      let location = 'top';
      if (start) location = 'left';
      else if (end) location = 'right';
      else if (bottom) location = 'bottom';

      /**
       * applies `cursor: help` on the hovered element if no cursor logic is applied
       */
      // if (!el.className.match(/cursor-/) && !el.style.cursor) el.style.cursor = 'help';

      let vNode = createVNode(
        VTooltip,
        {
          activator: el,
          location,
          contentClass: "!text-base"
        },
        () => [value],
      );

      vNode.appContext = vueApp._context;
      render(vNode, el);

      tooltips.push({ el });
    },
  });
});