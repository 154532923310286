
// import { aliases, mdi } from 'vuetify/iconsets/mdi-svg'
import '@mdi/font/css/materialdesignicons.css' // Ensure you are using css-loader

import { VBtn } from 'vuetify/components/VBtn'

import { createVuetify } from 'vuetify'

// import { VNumberInput } from 'vuetify/labs/VNumberInput'

const DARK_ORANGE_THEME = {
    name: 'DARK_ORANGE_THEME',
    dark: true,
    variables: {
        'border-color': '#333F55',
    },
    colors: {
        primary: '#0A7EA4',
        secondary: '#CCDA4E',
        info: '#539BFF',
        success: '#0ea529',
        accent: '#FA896B',
        warning: '#FFAE1F',
        error: '#FA896B',
        lightprimary: '#05313F',
        lightsecondary: '#282917',
        lightsuccess: '#1B3C48',
        lighterror: '#4B313D',
        lightinfo:'#223662',
        lightwarning: '#4D3A2A',
        textPrimary: '#EAEFF4',
        textSecondary: '#7C8FAC',
        borderColor: '#333F55',
        inputBorder: '#465670',
        containerBg: '#ffffff',
        background: '#ffffff',
        hoverColor: '#333f55',
        surface: '#171c23',
        'on-surface-variant': '#FFAE1F',
        grey100: '#333F55',
        grey200: '#465670'
    }
};



const BLUE_THEME = {
  name: 'BLUE_THEME',
  dark: false,
  variables: {
      'border-color': '#303030',
  },
  
  colors: {
      primary: '#5D87FF',
      secondary: '#49BEFF',
      info: '#539BFF',
      success: '#0ea529',
      accent: '#FFAB91',
      warning: '#FFAE1F',
      error: '#FA896B',
      danger: "#bb0606",
      lightprimary: '#FBF2EF',
      lightsecondary: '#EFF9FF',
      lightsuccess: '#E6FFFA',
      lighterror: '#FDEDE8',
      lightinfo:'#EBF3FE',
      lightwarning: '#FEF5E5',
      textPrimary: '#2A3547',
      textSecondary: '#2A3547',
      borderColor: '#e5eaef',
      inputBorder: '#303030',
      containerBg: '#f06161',
      background: '#FFFDF9',
      hoverColor: '#f6f9fc',
      // surface: '#fff',
      // 'on-surface-variant': '#2A3547',
      'on-primary': '#ffffff',
      // 'surface-bright': '#2A3547',
      grey100: '#F2F6FA',
      grey200: '#EAEFF4',
      lightGray: "#979797",
      darkGray: "#303030",
      burgundy: "#721313",
      borderColor: "#303030",
      offWhite: "#FFFDF9",
      cream: "#FAF4E7",
      "on-cream-lighten": "#F96425",
      beige: "#E4D9C9",
      // cream: "#FAF4E7",
      orange: "#f9b850",
      orangeDark: "#F96425",
      carmin: "#B42235"
  }
};



const TABLE_THEME = {
  name: 'TABLE_THEME',
  dark: false,  
  variables: {
    'border-color': '#555555',
    // 'on-background': '#2a3447',
    'on-surface': "#2A3547",
    "hover-opacity": 0.34,
  },
  colors: {
      primary: '#f9b850',
      secondary: '#F96425',
      info: '#539BFF',
      success: '#0ea529',
      accent: '#FFAB91',
      warning: '#ffe57f',
      error: '#FA896B',
      lightprimary: '#FBF2EF',
      lightsecondary: '#EFF9FF',
      lightsuccess: '#223662',
      lighterror: '#FDEDE8',
      lightinfo:'#EBF3FE',
      lightwarning: '#FEF5E5',
      textPrimary: '#2A3547',
      textSecondary: '#2A3547',
      borderColor: '#e5eaef',
      inputBorder: '#303030',
      containerBg: '#b4D9C9',
      background: '#f06161',
      hoverColor: '#f6f9fc',
      // surface: '#FAF4E7',
      burgundy: "#721313",
      'on-surface-variant': '#FFAE1F',
      // 'on-background': '#2a3447',
     
  }
};





const CARD_THEME = {
  name: 'CARD_THEME',
  dark: false,
  variables: {
    "medium-emphasis-opacity":1,
    'border-opacity': 0.84,
  },
  colors: {
      primary: '#FA896B',
      secondary: '#0074BA',
      info: '#539BFF',
      success: '#0ea529',
      accent: '#0A7EA4',
      warning: '#ffe57f',
      error: '#FA896B',
      lightprimary: '#FFAE1F',
      lightsecondary: '#FFAE1F',
      lightsuccess: '#1B3C48',
      lighterror: 'blue',
      lightinfo:'blue',
      // surface: '#7C8FAC',
      textPrimary: '#2A3547',
      lightwarning: '#FEF5E5',
      borderColor: '#303030',
      hoverColor: '#f6f9fc',
      cardTextPadding: '6px',
      themeHoverOpacity: 0.84,
      'on-surface': '#202030',
      'high-emphasis-opacity': 1,
      'on-surface-variant': '#FFAE1F',
      'on-primary': '#13DEB9',
      'hover-opacity': 0.84,
      'theme-hover-opacity': 0.84,
      'focus-opacity': 0.84,
      
  }
}
const APP_THEME = {
  name: 'APP_THEME',
  dark: false,
  variables: {
      'border-color': '#555555',
      'card-text-padding': '6px',
      'prepend-width': '6px',
      'prepend-width': '6px',
  },
  colors: {
      primary: '#5D87FF',
      secondary: '#49BEFF',
      info: '#539BFF',
      success: '#0ea529',
      accent: '#FFAB91',
      warning: '#FFAE1F',
      error: '#FA296B',
      lightprimary: '#ECF2FF',
      lightsecondary: '#E8F7FF',
      lightsuccess: '#E6FFFA',
      lighterror: '#FDEDE8',
      lightinfo:'#EBF3FE',
      lightwarning: '#FEF5E5',
      textPrimary: '#2A3547',
      textSecondary: '#2A3547',
      borderColor: '#555555',
      inputBorder: '#DFE5EF',
      containerBg: '#FFFDF9',
      background: '#f6f9fc',
      hoverColor: '#f6f9fc',
      surface: '#0ea529',
      'on-surface-variant': '#0ea529',
      'hover-opacity': 0.84,
      grey100: '#F2F6FA',
      grey200: '#EAEFF4',
      lightGray: "#979797",
      darkGray: "#303030",
      burgundy: "#721313",
      borderColor: "#432227",
      offWhite: "#FFFDF9",
      cream: "#FAF4E7",
      beige: "#E4D9C9",
      orange: "#f9b850",
      orangeDark: "#F96425",
      carmin: "#B42235"
  }
};

// import { VCard } from 'vuetify/components/VCard'
export default defineNuxtPlugin((app) => {
  const vuetify = createVuetify({
    theme: {
      defaultTheme: 'BLUE_THEME',
      variations: {
        colors: ['beige'],
        darken: 2,
        lighten: 1, 
      },
      themes: { DARK_ORANGE_THEME, BLUE_THEME, TABLE_THEME, CARD_THEME },
      locale: {
        locale: 'fr',
      },
    },
    aliases: {
      VBtnSecondary: VBtn,
      VBtnSuccess: VBtn,
    },
    defaults: {
      VBtnSecondary: {
        color: 'secondary',
        variant: 'flat',
        theme: 'CARD_THEME',
      },
      VBtnSuccess: {
        rounded: true,
        variant: 'flat',
        theme: 'CARD_THEME',
        color: 'success',
        bgColor: 'success',
      },
      VSwitch: {
        density: "compact",
        variant:"underlined",
        theme: 'CARD_THEME',
      },
      VTextField: {
        variant:"underlined",
        density: "comfortable",
      },
      VSelect: {
        variant:"underlined",
        density: "comfortable",
      },
      VAutocomplete: {
        variant:"underlined",
        itemValue: "id",
        density: "comfortable",
      },
      VExpansionPanelTitle: {
        class: "!text-lg",
      }
      // VApp
    },
    // components: {
    //   VNumberInput,
    //   // VCard,
    // },
  })
  app.vueApp.use(vuetify)
})


// export default defineVuetifyConfiguration({
//   ssr: true,
//   theme: {
//     defaultTheme: 'DARK_ORANGE_THEME',
//     variations: {
//       // colors: ['orange', 'beige','cream', 'lightGray'],
//       // darken: 2,
//     },
//     themes: {  BLUE_THEME, TABLE_THEME, CARD_THEME, DARK_ORANGE_THEME}
//   },
//   defaults: {
//     // global: {
//     //   ripple: false,
//     // },
//     VTextField: {
//       variant:"underlined",
//       density: "comfortable",
//     },
//     VSwitch: {
//       density: "compact",
//       variant:"underlined"
//     },
//     VAutocomplete: {
//       variant:"underlined",
//       itemValue: "id",
//       density: "comfortable",
//     }
//   },
  
//   // labComponents: ['VDataTableRow','VDataTableRows', 'VDataTableServer'] // or ['VDataTable']
// })