<template>
  <v-app theme="BLUE_THEME" class=" verticalLayout" v-if="route.name === 'login'">
    <v-container fluid class="page-wrapper !p-0">
      <NuxtPage />
    </v-container>
  </v-app>
  <v-app theme="BLUE_THEME" class=" verticalLayout" v-else>
    <LayoutLoading />
    
    <v-alert transition="fade-transition" class="z-50 !opacity-100 w-1/3 inset-x-1/3 top-24" v-model="alert" position="fixed" closable 
      icon="mdi-alert"
      :type="messageVariant" :title="messageTitle"><div class="text-center font-bold" v-html="message"></div>
    </v-alert>
    <v-main>
      <!-- <HelpSidebar /> -->
      <VerticalSidebarVue v-if="route.name != 'login'" />
      <VerticalHeaderVue />
      
      <v-container fluid class="page-wrapper pb-sm-15 pb-10">
          <NuxtPage />
      </v-container>
      <div id="submit-placeholder" class="fixed bottom-0 w-full w-1/3  ">  </div>
    </v-main>
    
  </v-app>
</template>

<script setup>

useHead({
  title: 'Admin La Cave des Papilles',
  link:[
    { rel: "apple-touch-icon", sizes: "180x180", href: "/apple-touch-icon.png" },
    { rel: "icon", type: "image/png", sizes: "32x32", href: "/favicon-32x32.png" },
    { rel: "icon", type: "image/png", sizes: "16x16", href: "/favicon-16x16.png" },
    { rel: "manifest", href: "/site.webmanifest"}
  ]
})

// import { VApp, VMain, VAlert, VContainer } from "vuetify/lib/components/index.mjs";
const alert = ref(false)
const message = ref("")
const messageVariant = ref("success")
const messageTitle = ref("")
const route = useRoute()
import VerticalHeaderVue from "@/components/Layout/vertical-header/VerticalHeader.vue";
import VerticalSidebarVue from '@/components/Layout/vertical-sidebar/VerticalSidebar.vue';
// import HelpSidebar from '@/components/Layout/help-sidebar.vue';
const { $customEventBus } = useNuxtApp();
$customEventBus.$on('notice', (messageData) => {
  console.log(messageData)
  messageTitle.value = messageData.type == "error" ? "Erreur" : "Succès";
  messageVariant.value = messageData.type
  alert.value = true;
  message.value = messageData.message
  let timeout = setTimeout(() => {
    alert.value = false;
    clearTimeout(timeout)
  }, 3000);
});
</script>
<style>
.v-main {
  padding-top: 70px;
}

.v-alert {
  z-index: 10000;
}
</style>